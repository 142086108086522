<template>
	<b-card class="rounded-0 border-0">
		<div class="d-flex flex-column position-relative">
			<w-input class="w-100 z-coupon-input" v-model="$v.coupon" label="Zadajte kód z Vášho kupónu/poukážky " chevron />
			<b-button class="apply-coupon" :disabled="!coupon || isLoading" variant="success" @click="onUseCoupon">Použiť </b-button>
		</div>
		<div class="mt-3">Najprv zvoľte spôsob platby</div>
		<div v-if="orderCoupon && orderPrice" class="coupon-used mt-3">
			Kupón <strong>{{ orderCoupon.coupon_code }}</strong> bol aktivovaný. Bola aplikovaná zľava <strong>{{ discount }}€</strong> <span v-if="orderCoupon.discount_is_percentage"> ({{ orderCoupon.discount_value }}%)</span>.
		</div>
	</b-card>
</template>

<script>
import { mapState } from 'vuex'
import { t } from '@/plugins/w/translate/translations'

export default {
	data() {
		return {
			coupon: null,
			isLoading: false,
			haveDiscount: false
		}
	},

	validations() {
		return {
			coupon: {
				haveDiscount: function() {
					return this.haveDiscount
				}
			}
		}
	},

	computed: {
		...mapState('order', {
			orderCoupon: 'coupon',
			orderIsPartialPayment: 'isPartialPayment',
			orderPrice: 'orderPrice'
		}),

		discount() {
			if (this.orderCoupon.discount_is_percentage) {
				const totalPrice = this.orderIsPartialPayment ? this.orderPrice.order_monthly_pay.total_price : this.orderPrice.order_once_pay.total_price
				return ((totalPrice / 100) * this.orderCoupon.discount_value).toFixed(2)
			}

			return this.orderCoupon.discount_value
		}
	},

	methods: {
		t,

		async onUseCoupon() {
			this.isLoading = true

			try {
				const couponDiscount = await this.$store.dispatch('order/useCoupon', {
					coupon_code: this.coupon
				})
				this.haveDiscount = !!couponDiscount
			} catch (err) {
				this.$wToast.error(this.t(err.data.error))
				this.haveDiscount = false
			} finally {
				this.$v.$touch()
				this.isLoading = false
			}
		}
	}
}
</script>

<style lang="sass" scoped>
.apply-coupon
	padding-left: 20px
	padding-right: 20px
	position: absolute
	right: 0
	bottom: 0
	z-index: 9
	color: #fff
	font-weight: 600
	background-color: #6CBD7A
	border-radius: 2px

	&[disabled]
		background: rgba(232, 228, 218, 0.2)
		border: 1px solid #E8E4DA
		color: #54514B

.coupon-used
	color: #7dC15B
</style>
